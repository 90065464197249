.level-card {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.level-card p {
  padding: 0;
  margin: 0;
}

.custom-header {
  background-color: #6259ca !important;
}

.custom-header h3 {
  color: #fff;
}

.custom-header .btn-outline-success {
  color: #fff !important;
  outline-color: #fff !important;
  border-color: #fff !important;
}

.custom-header .btn-outline-success:hover {
  background-color: #fff !important;
  color: #000 !important;
}

.card-shadow {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px,
    rgba(0, 0, 0, 0.05) 0px 4px 6px -2px !important;
}
