.challenge-arena h6 {
  font-weight: 400;
  font-size: 16px;
  color: #3c4b64;
}
.challenge-arena h5 {
  font-size: 16px;
  font-weight: 500;
  color: #000;
  margin-bottom: 18px;
}

ul.MuiPagination-ul.css-wjh20t-MuiPagination-ul {
  display: flex;
  justify-content: center;
}
