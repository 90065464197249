.ql-container {
  min-height: 80vh !important;
}

.ql-picker.ql-font {
  .ql-picker-item {
    font-size: 0;
    &:before {
      content: attr(data-value) !important;
      font-size: 14px;
    }
  }
}

.ql-picker.ql-font{
  .ql-active {
    &:before {
      content: attr(data-value) !important;
      font-size: 14px;
    }
  }
}

.ql-font-Roboto {
  font-family: 'Roboto', sans-serif;
}

.ql-font-Inter {
  font-family: 'Inter', sans-serif;
}

.ql-editor strong{
  font-weight: bold;
}
