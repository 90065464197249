/** Styles copied from Figma dev mode */

.cardPaper {
  width: 420px;
  overflow: hidden;
  border-radius: 16px !important;
  background: #FFF;
  box-shadow: 12px 12px 24px 0px rgba(178, 178, 180, 0.24);
  position: relative;
}

.cardImage {
  height: 150px;
}

.cardContent {
  padding: 28px 14px 36px 14px;
}

.cardTitle {
  color: var(--theme-dark-default, #16192C) !important;
  font-family: Roboto !important;
  font-size: 20px !important;
  font-style: normal !important;
  font-weight: 600 !important;
  line-height: 28px !important;
  margin-right: 8px !important;
}

.cardText {
  color: var(--text-body-light, #425466) !important;
  font-feature-settings: 'salt' on !important;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: 23px !important;
  margin-top: 4px !important;
  min-height: 70px !important;
  max-height: 70px !important;
  overflow-y: hidden;
  transition: all 2s;
}

.cardText:hover {
  max-height: 500px !important;
}